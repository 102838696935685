import history from "../helpers/history";
import {store} from '../redux/store';
import Notifications from "react-notification-system-redux";
import {errorToNotifObj, getLSLang} from "../helpers";

import {getIdToken} from './auth'; //isLoggedIn
import _ from 'lodash';
import axios from "axios";
let base_url = "https://patientcareapi.dsegura.net" //"https://patientcareapi.dsegura.net/" //"http://209.126.104.107:8077/";
// if(window.location.hostname.indexOf("test") === 0 || window.location.hostname.indexOf("locsalhost") !== -1 ){
//     base_url = "http://209.126.104.107:8077/";
// }
export {base_url};
export const API = axios.create({
    baseURL: base_url,
    // timeout: 1000,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "es-US" }
})
export const API_PUBLIC = axios.create({
    baseURL: base_url,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "en-US" }
})
API_PUBLIC.interceptors.request.use(function (request) {
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    return request;
})
API_PUBLIC.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // if(error.response.config.url.toLowerCase() === "login") return Promise.reject(error);
    //
    // if (error.response.data) {
    //     let obj = errorToNotifObj(error.response.data)
    //     store.dispatch(Notifications.error(obj));
    // }else{
    //     store.dispatch(Notifications.error(errorToNotifObj(error.response)));
    // }
    return Promise.reject(error);
});

API.interceptors.request.use(function (request) {
    const tokenId = getIdToken();
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    // const decoded = jwt_decode(localStorage._token);
    //
    // const currentTime = Date.now() / 1000;
    // if(decoded.exp < currentTime) {
    //     store.dispatch(logoutUser());
    //     window.location.href = '/login'
    // }
    //
    if (tokenId) request.headers.Authorization = `Bearer ${tokenId}`;

   // if (!window.navigator.onLine) alert("no estas conectado a internet");
    return request;
})
API.interceptors.response.use(function (response) {
    //notificaciones en redux
    let reduxNot = ["compras", "farmacias","canjes"];
    let url = response.config.url.toLowerCase();


    if(!reduxNot.includes(url) && response.config.method !== "post" && url.indexOf("autorizaciones") === -1 ){
        let msj = "";
        switch (response.config.method) {
            case "post":
                msj = "Registro agregado";
                break
            case "put":
                msj = "Registro actualizado";
                break
            case "delete":
                msj = "Registro eliminado";
                break
            default:
                msj = "";
        }
        if (msj)
            store.dispatch(Notifications.success({title: msj + ' con exito.', autoDismiss: 4}));
    }

    return response;
}, function (error) {
    if(error.response.config.url.toLowerCase() === "profile") return Promise.reject(error);

    if (error.response.data) {
        if (error.response.status === 401) {
            localStorage.removeItem('_token');
            if (history.location.pathname.indexOf("/report") !== -1) {
                window.close();
            }
            history.replace("/login", { backurl: history.location.pathname })
        } else if (error.response.status === 403) {
            store.dispatch(Notifications.warning({ title: 'Acceso denegado', message: 'Necesita permisos para realizar esta acción.', autoDismiss: 5 }));
        } else {
            let obj = errorToNotifObj(error.response.data)
            store.dispatch(Notifications.error(obj));
        }
    }else{
        store.dispatch(Notifications.error(errorToNotifObj(error.response)));
    }
    return Promise.reject(error);
});

//Profile
export function getPerfil() {
    return API.get('Profile');
}
export function ediPerfil(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Profile', params);
}
//Articulos
export function getArticulos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('articulos', { params });
}
export function getArticulosById(id) {
    return API.get('articulos/' + id);
}
export function addEditArticulos(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('articulos/'+obj.id, params): API.post('articulos', params);
}
export function delArticulo(id) {
    return API.delete(`articulos/${id}`);
}
export function blockArticulo(id) {
    return API.post('articulos/' + id+'/bloquear');
}
//ArticulosCategorias
export function getArticulosCategorias(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('ArticulosCategorias', { params });
}
export function addEditArticulosCategoria(obj) {
    let params = _.omitBy({ic:obj?.id , name:obj?.catNombre}, (v) => !v);
    return obj.id ? API.put('ArticulosCategorias/'+obj.id, params): API.post('ArticulosCategorias', params);
}
export function delArticulosCategoria(id) {
    return API.delete(`ArticulosCategorias/${id}`);
}
//ArticulosMarcas
export function getArticulosMarcas(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('ArticulosMarcas', { params });
}
export function addEditArticulosMarca(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('ArticulosMarcas/'+obj.id, params): API.post('ArticulosMarcas', params);
}
export function delArticulosMarca(id) {
    return API.delete(`ArticulosMarcas/${id}`);
}
//Contactos
export function getContactos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Contactos', { params });
}
export function getContactoById(id) {
    return API.get('Contactos/' + id);
}
export function addEditContacto(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Contactos/'+obj.id, params): API.post('Contactos', params);
}
export function delContacto(id) {
    return API.delete(`Contactos/${id}`);
}

//Empresas
export function getEmpresas(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Empresas', { params });
}
export function getEmpresaById(id) {
    return API.get('Empresas/' + id);
}
export function addEditEmpresa(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Empresas/'+obj.id, params): API.post('Empresas', params);
}
export function delEmpresa(id) {
    return API.delete(`Empresas/${id}`);
}
//Planes
export function getPlanes(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Planes', { params });
}
export function getPlanById(id) {
    return API.get('Planes/' + id);
}
export function addEditPlan(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Planes/'+obj.id, params): API.post('Planes', params);
}
export function delPlan(id) {
    return API.delete(`Planes/${id}`);
}
export function getArticulosPlanId(id, verVentaLibre=false) {

    return API.get(`/Planes/${id}/Articulos`,{params:verVentaLibre !== undefined ? {}: {verVentaLibre}});
}
export function getArticulosCanjePlanId(id, name="") {
    return API.get(`/Planes/${id}/ArticulosCanje`,{params:{name}});
}
//Centro Medicos
export function getCentrosMedicos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('CentrosMedicos', { params });
}
export function getCentroMedicoById(id) {
    return API.get('CentrosMedicos/' + id);
}
export function addEditCentroMedico(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('CentrosMedicos/'+obj.id, params): API.post('CentrosMedicos', params);
}
export function delCentroMedico(id) {
    return API.delete(`CentrosMedicos/${id}`);
}
//Medicos
export function getMedicos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Medicos', { params });
}
export function getMedicoById(id) {
    return API.get('Medicos/' + id);
}
export function addEditMedico(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Medicos/'+obj.id, params): API.post('Medicos', params);
}
export function delMedico(id) {
    return API.delete(`Medicos/${id}`);
}
//Ofertas
export function getOfertas(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Ofertas', { params });
}
export function getOfertaById(id) {
    return API.get('Ofertas/' + id);
}
export function addEditOferta(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Ofertas/'+obj.id, params): API.post('Ofertas', params);
}
export function delOferta(id) {
    return API.delete(`Ofertas/${id}`);
}
export function getOfertasDisponible(farmaciaId) {
    return API.get(`Ofertas/VerOfertasDisponible/?farmaciaId=${farmaciaId}`);
}
//Compras
export function getCompras(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Compras', { params });
}
export function getCompraById(id) {
    return API.get('Compras/' + id);
}
export function addEditCompra(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Compras/'+obj.id, params): API.post('Compras', params);
}
export function delCompra(id) {
    return API.delete(`Compras/${id}`);
}
export function validarPuntos(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post("/Compras/ValidarPuntos",params);
}
//Farmacias
export function getFarmacias(obj, simple=false) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Farmacias'+(simple?"/GetListaSimple":""), { params });
}
export function getFarmaciaById(id) {
    return API.get('Farmacias/' + id);
}
export function addEditFarmacia(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Farmacias/'+obj.id, params): API.post('Farmacias', params);
}
export function delFarmacia(id) {
    return API.delete(`Farmacias/${id}`);
}
export function generateUsuarioFarmacia(id) {
    return API.post(`Farmacias/${id}/GenerarUsuario`);
}
//Tarjetas
export function getTarjetas(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Tarjetas', { params });
}
export function getTarjetaById(id) {
    return API.get('Tarjetas/' + id);
}
export function addEditTarjeta(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Tarjetas/'+obj.id, params): API.post('Tarjetas', params);
}
export function genTarjeta(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('/Tarjetas/Generar'+obj.id, params): API.post('/Tarjetas/Generar', params);
}
export function delTarjeta(id) {
    return API.delete(`Tarjetas/${id}`);
}
//Canjes
export function getCanjes(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Canjes', { params });
}
export function getCanjeById(id) {
    return API.get('Canjes/' + id);
}
export function addEditCanje(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Canjes/'+obj.id, params): API.post(`Canjes`, params);
}
export function delCanje(id) {
    return API.delete(`Canjes/${id}`);
}
//Transacciones
export function getTransacciones(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Transacciones', { params });
}

//Pacientes
export function getPacientes(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Pacientes', { params });
}
export function getPacienteDashboard(id) {
    return API.get(`/Reportes/Dashboard/?pacienteId=${id}`);
}

export function getPacientesListSimple(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Pacientes/GetListaSimple', { params });
}
export function getPacienteById(id) {
    return API.get('Pacientes/' + id);
}
export function getPacienteByIdDetalle(id) {
    return API.get(`/Pacientes/${id}/Detalle`);
}
export function addEditPaciente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Pacientes/'+obj.id, params): API.post('RegistroPublico', params);
}
export function addPacientePublico(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('RegistroPublico', params);
}
export function generateUsuarioPaciente(id) {
    return API.post(`Pacientes/${id}/GenerarUsuario`);
}
export function delPaciente(id) {
    return API.delete(`Pacientes/${id}`);
}
export function getArticulosByPacienteId(id) {
    return API.get(`/Pacientes/${id}/ArticulosCompra`)
}
export function getArticulosCanjeByPacienteId(id) {
    return API.get(`/Pacientes/${id}/ArticulosCanje`)
}

//Indicaciones
export function getIndicacionesById(id) {
    return API.get('PacientesIndicaciones/' + id);

}
export function addEditPacientesIndicaciones(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('PacientesIndicaciones/'+obj.id, params): API.post('PacientesIndicaciones', params);
}
export function delPacientesIndicaciones(id) {
    return API.delete(`PacientesIndicaciones/${id}`);
}

//Parientes
export function getParientes(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Parientes', { params });
}
export function getParienteById(id) {
    return API.get('Parientes/' + id);
}
export function addEditPariente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Parientes/'+obj.id, params): API.post('Parientes', params);
}
export function delPariente(id) {
    return API.delete(`Parientes/${id}`);
}
export function generateUsuarioPariente(id) {
    return API.post(`Parientes/${id}/GenerarUsuario`);
}

//Almacen
export function getAlmacenes(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Almacenes', { params });
}
export function getAlmacenById(id) {
    return API.get('Almacenes/' + id);
}
export function addEditAlmacen(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Almacenes/'+obj.id, params): API.post('Almacenes', params);
}
export function delAlmacen(id) {
    return API.delete(`Almacenes/${id}`);
}
//Impuestos
export function getImpuestos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Impuestos', { params });
}
export function getImpuestoById(id) {
    return API.get('Impuestos/' + id);
}
export function addEditImpuesto(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Impuestos/'+obj.id, params): API.post('Impuestos', params);
}
export function delImpuesto(id) {
    return API.delete(`Impuestos/${id}`);
}
//Roles
export function getRoles(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Roles', { params });
}
export function getRolById(id) {
    return API.get('Roles/' + id);
}
export function addEditRol(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Roles/'+obj.id, params): API.post('Roles', params);
}
export function delRol(id) {
    return API.delete(`Roles/${id}`);
}

//permisos
export function getPermisos() {
    return API.get('Permisos');
}

//Usuarios
export function getUsuarios(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Usuarios', { params });
}
export function getUsuarioById(id) {
    return API.get('Usuarios/' + id);
}
export function addEditUsuario(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Usuarios/'+obj.id, params): API.post('Usuarios', params);
}
export function editPWUsuario(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post(`Usuarios/${obj.id}/CambiarClave`, params);
}
export function delUsuario(id) {
    return API.delete(`Usuarios/${id}`);
}
export function lockToggleUsuario(id) {
    return API.post(`Usuarios/${id}/bloquear`);
}



/*-------------Otros Mantenimientos--------------------*/
//getUsosMultiples
export function getUsosMultiples(usoGrupo, name) {
    return API.get('UsosMultiples', { params: { usoGrupo, name } });
}
export function addEditUsosMultiple(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('UsosMultiples/'+obj.id, params): API.post('UsosMultiples', params);
}

export function delUsosMultiple(id) {
    return API.delete(`UsosMultiples/${id}` );
}


//Estados
export function getEstados() {
    return API.get('Estados');
}
//Paises
export function getPaises() {
    return API.get('/Paises');
}
export function getProvinciasPorPais(id) {
    return API.get(`/Paises/${id}/Provincias`);
}
//Provincias
export function getProvincias() {
    return API.get('/Provincias');
}
export function getMunicipiosPorProvincias(id) {
    return API.get(`/Provincias/${id}/Municipios`);
}
export function getSectoresPorMunicipios(id) {
    return API.get(`/Municipios/${id}/Sectores`);
}
//Enums
export function getEnums() {
    return API.get('comunes/Enums');
}
export function savePushToken(params) {
    let obj = _.omitBy(params, (v) => !v);
    return API.post('/Comunes/SesionToken', obj);
}
//notificaciones
export function getNotificaciones(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.get('Notificaciones', {params});
}
export function marcarNotLeida(id) {
    return API.post(`/Notificaciones/${id}/MarcarLeida`);
}
//Autorizaciones
export function getAutorizaciones(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Autorizaciones', {params});
}
export function ApproveAutorizacion({id,...obj}) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post(`/Autorizaciones/${id}/Aprobar`,params);
}
export function RejectAutorizacion({id,...obj}) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post(`/Autorizaciones/${id}/Rechazar`,params);
}
export function getAutorizacionesNumber() {
    return API.get('/Reportes/Autorizaciones');
}

//admin
export function getAppUsers(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('admin', { params });
}
export function getAppUsersById(id) {
    return API.get('admin/GetById/' + id);
}
export function putAppUser(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('admin', params);
}
export function getAdminEnums() {
    return API.get('Admin/GetEnums');
}


//auth
export function PostRegister(obj, validate) {
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('Register' + (validate ? "?validar=true" : ""), params);
}
export function changePassword(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ChangePassword', params);
}
export function forgotPassword(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('ForgotPassword', params);
}
export function forgotPasswordConfirm(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ForgotPasswordConfirm', params);
}

//piblic
export function getPublicUser(user) {
    return API_PUBLIC.get('Public/' + user);
}
export function getGeo() {
    return axios.get("https://ipapi.co/json");
}
