import Canjes from '../views/Canjes';
import Transacciones from '../views/Transacciones';
import Compras from '../views/Compras';
import CrearCompra from '../views/Compras/crearCompra';

import Perfil from '../views/cuenta/perfil';
import inicio from '../views/inicio/inicio2';

import Articulos from '../views/Articulos';
import ArticuloMarcas from "../views/Articulos/ArticuloMarcas";
import Categorias from "../views/Articulos/Categorias";
import CrearArticulo from "../views/Articulos/crearArticulo";

import Planes from "../views/Planes";
import CrearPlan from '../views/Planes/crearPlan';

import CentrosMedicos from "../views/CentrosMedicos";
import CrearCentroMedico from '../views/CentrosMedicos/crearCentroMedico';

import Medicos from "../views/Medicos";
import CrearMedico from '../views/Medicos/crearMedico';

import Ofertas from "../views/Ofertas";
import CrearOferta from '../views/Ofertas/crearOferta';

import Farmacias from "../views/Farmacia";
import DetallesFarmacia from '../views/Farmacia/detallesFarmacia';

import CrearFarmacia from '../views/Farmacia/crearFarmacia';

import Pacientes from "../views/Pacientes";
import EditarPaciente from '../views/Pacientes/editarPaciente';
import EditarPariente from '../views/Pacientes/editarPariente';

import DetallesPaciente from '../views/Pacientes/detallesPaciente';


import Tarjetas from "../views/Tarjetas";

import Roles from '../views/roles';
import Usuarios from '../views/Usuarios';



import Autorizaciones from '../views/Autorizaciones';
//import Notificaciones from '../views/Notificaciones';
import Paises from '../views/Paises';
import UsosMultiples from '../views/usosMultiples';


//Reportes
//const Enums = lazy(() => import('../views/enums'));
import { AccountLockOutline, AccountOutline, BookmarkMultipleOutline, CardAccountDetailsOutline, ClipboardAccountOutline, Doctor, FormatListBulleted, HospitalBuilding, LabelPercentOutline, ReceiptOutline, ShieldKeyOutline, StoreOutline, SwapHorizontal, ViewDashboardOutline } from "mdi-material-ui";

import { getUsuTipo, hasPermissionTo, PERSMISOS, TIPOUSUARIO } from "../helpers";
import { IRoutes } from "../redux/types/IRoutes";


const getRoutes = () =>{
    let obj:IRoutes[] = [
        ...((getUsuTipo() === TIPOUSUARIO.paciente || getUsuTipo() === TIPOUSUARIO.pariente) ?
            [{
            path: "/inicio",
            sidebarName: "inicio",
            navbarName: "inicio",
            icon: ViewDashboardOutline,
            component: inicio,
            }]: []
        ),
        {
            path: "/compras",
            sidebarName: "compras",
            navbarName: "compras",
            icon: ReceiptOutline ,
            restricted: !hasPermissionTo(PERSMISOS.compras),
            component: Compras,
            nestedRoutes: [
                {
                    path: "/crear/compra",
                    sidebarName: "Crear Compra",
                    navbarName: "Crear Compra",
                    component: CrearCompra
                }
            ]
        },
        {
            path: "/perfil",
            component: Perfil,
            icon: AccountOutline,
        },
        {
            path: "/canjes",
            sidebarName: "canjes",
            navbarName: "canjes",
            icon: SwapHorizontal ,
            restricted: !hasPermissionTo(PERSMISOS.canjes),
            component: Canjes,
        },
        {
            path: "/transacciones",
            sidebarName: "transacciones",
            navbarName: "transacciones",
            icon: SwapHorizontal ,
          //  restricted: !hasPermissionTo(PERSMISOS.canjes),
            component: Transacciones,
        },
        ...((getUsuTipo() !== TIPOUSUARIO.farmacia) ?
            [{
                path: "/pacientes",
                sidebarName: getUsuTipo() === TIPOUSUARIO.paciente? undefined :(getUsuTipo() === TIPOUSUARIO.pariente? "misDependientes" :"pacientes"),
                navbarName: "pacientes",
                icon: ClipboardAccountOutline,
                restricted: !hasPermissionTo(PERSMISOS.pacientes),
                component: Pacientes,
                nestedRoutes: [
                    {
                        path: "/detalles/paciente/:id",
                        sidebarName: "detalles paciente",
                        navbarName: "detalles paciente",
                        component: DetallesPaciente
                    },
                    {
                        path: "/editar/paciente/:id",
                        sidebarName: "editar Paciente",
                        navbarName: "editar Paciente",
                        component: EditarPaciente
                    },
                    // {
                    //     path: "/crear/pariente/:pacId",
                    //     sidebarName: "editar Paciente",
                    //     navbarName: "editar Paciente",
                    //     component: EditarPariente
                    // },
                    // {
                    //     path: "/editar/pariente/:pacId/:id",
                    //     sidebarName: "editar Paciente",
                    //     navbarName: "editar Paciente",
                    //     component: EditarPariente
                    // },

                    {
                        path: "/crear/pariente/:id",
                        sidebarName: "editar Paciente",
                        navbarName: "editar Paciente",
                        component: EditarPariente
                    },
                    {
                        path: "/editar/pariente/:id",
                        sidebarName: "editar Paciente",
                        navbarName: "editar Paciente",
                        component: EditarPariente
                    },
                ]
            }]: []
        ),
        ...((getUsuTipo() === TIPOUSUARIO.admin || getUsuTipo() === TIPOUSUARIO.farmacia) ?
            [
        {
            path: "/farmacias",
            sidebarName: getUsuTipo() === TIPOUSUARIO.paciente? undefined :"farmacias",
            navbarName: "farmacias",
            icon: StoreOutline,
            restricted: !hasPermissionTo(PERSMISOS.farmacias),
            component: Farmacias,
            nestedRoutes: [
                {
                    path: "/detalles/farmacia/:id",
                    sidebarName: "detalles Farmacia",
                    navbarName: "detalles Farmacia",
                    component: DetallesFarmacia
                },
                {
                    path: "/crear/farmacia",
                    sidebarName: "Crear Farmacia",
                    navbarName: "Crear Farmacia",
                    component: CrearFarmacia
                },
                {
                    path: "/editar/farmacia/:id",
                    sidebarName: "editar Farmacia",
                    navbarName: "editar Farmacia",
                    component: CrearFarmacia
                },
                {
                    path: "/crearSucursal/farmacia/:farPrincipal",
                    sidebarName: "editar Farmacia",
                    navbarName: "editar Farmacia",
                    component: CrearFarmacia
                },
            ]
        }]: []
        ),


        ...(getUsuTipo() === TIPOUSUARIO.admin ?
        [
            {
                sidebarName: "configuracion",
                navbarName: "configuracion",
                icon: FormatListBulleted,
                nestedRoutes: [
                    {
                        path: "/planes",
                        sidebarName: "planes",
                        navbarName: "planes",
                        icon: BookmarkMultipleOutline,
                        restricted: !hasPermissionTo(PERSMISOS.planes),
                        component: Planes,
                        nestedRoutes: [
                            {
                                path: "/crear/plan",
                                navbarName: "Crear Plan",
                                component: CrearPlan
                            },
                            {
                                path: "/editar/plan/:id",
                                navbarName: "editar Plan",
                                component: CrearPlan
                            },
                        ]
                    },
                    {
                        path: "/ofertas",
                        sidebarName: "ofertas",
                        navbarName: "ofertas",
                        icon: LabelPercentOutline,
                        restricted: !hasPermissionTo(PERSMISOS.ofertas),
                        component: Ofertas,
                        nestedRoutes: [
                            {
                                path: "/crear/oferta",
                                navbarName: "Crear Oferta",
                                component: CrearOferta
                            },
                            {
                                path: "/editar/oferta/:id",
                                navbarName: "editar Oferta",
                                component: CrearOferta
                            },
                        ]
                    },
                ]

            },
            {
                sidebarName: "seguridad",
                navbarName: "seguridad",
                icon: AccountLockOutline,
                restricted: !hasPermissionTo(PERSMISOS.seguridad),
                nestedRoutes: [
                    {
                        path: "/usuarios",
                        sidebarName: "usuarios",
                        navbarName: "usuarios",
                        component: Usuarios
                    },
                    {
                        path: "/roles",
                        sidebarName: "rolesPermisos",
                        navbarName: "rolesPermisos",
                        component: Roles
                    }
                ]
            },
            {
                sidebarName: "mantenimiento",
                navbarName: "mantenimiento",
                icon: FormatListBulleted,
                nestedRoutes: [
                    {
                        path: "/medicos",
                        sidebarName: "medicos",
                        navbarName: "medicos",
                        icon: Doctor,
                        restricted: !hasPermissionTo(PERSMISOS.medicos),
                        component: Medicos,
                        nestedRoutes:[
                            {
                                path: "/crear/medico",
                                navbarName: "Crear Medico",
                                component: CrearMedico
                            },
                            {
                                path: "/editar/medico/:id",
                                navbarName: "editar Medico",
                                component: CrearMedico
                            },
                        ]
                    },


                    //-------
                    {
                        path: "/centrosMedicos",
                        sidebarName: "centrosMedicos",
                        navbarName: "centrosMedicos",
                        icon: HospitalBuilding,
                        restricted: !hasPermissionTo(PERSMISOS.centrosMedicos),
                        component: CentrosMedicos,
                        nestedRoutes:[
                            {
                                path: "/crear/centroMedico",
                                navbarName: "Crear CentroMedico",
                                component: CrearCentroMedico
                            },
                            {
                                path: "/editar/centroMedico/:id",
                                navbarName: "editar CentroMedico",
                                component: CrearCentroMedico
                            },
                        ]
                    },

                    //---------
                    {
                        path: "/tarjetas",
                        sidebarName: "tarjetas",
                        navbarName: "tarjetas",
                        icon: CardAccountDetailsOutline,
                        restricted: !hasPermissionTo(PERSMISOS.tarjetas),
                        component: Tarjetas
                    },
                    {
                        path: "/articulos",
                        sidebarName: "articulos",
                        navbarName: "articulos",
                        restricted: !hasPermissionTo(PERSMISOS.articulos),
                        component: Articulos,
                        nestedRoutes:[
                            {
                                path: "/crear/articulo",
                                navbarName: "articulos",
                                component: CrearArticulo
                            },
                            {
                                path: "/editar/articulo/:id",
                                component: CrearArticulo
                            },
                        ]
                    },
                    {
                        path: "/categorias",
                        sidebarName: "categorias",
                        navbarName: "categorias",
                        restricted: !hasPermissionTo(PERSMISOS.lineas),
                        component: Categorias
                    },
                    {
                        path: "/marcas",
                        sidebarName: "marcas",
                        navbarName: "marcas",
                        restricted: !hasPermissionTo(PERSMISOS.marcas),
                        component: ArticuloMarcas
                    },
                    {
                        path: "/paises",
                        sidebarName: "paises",
                        navbarName: "paises",
                        component: Paises,
                        nestedRoutes:[
                            {
                                path: "/paises/:paiId",
                                navbarName: "provincias",
                                component: Paises
                            },
                            {
                                path: "/paises/:paiId/:proId",
                                navbarName: "monicipios",
                                component: Paises
                            },
                            {
                                path: "/paises/:paiId/:proId/:munId",
                                navbarName: "sectores",
                                component: Paises
                            },
                        ]
                    },
                    {
                        path: "/UsosMultiples",
                        sidebarName: "usosMultiples",
                        navbarName: "usosMultiples",
                        restricted: !hasPermissionTo(PERSMISOS.usosMultiples),
                        component: UsosMultiples
                    },
                ]
            },
            // {
            //     path: "/notificaciones",
            //     sidebarName: "notificaciones",
            //     navbarName: "notificaciones",
            //     icon: BellOutline,
            //     component: Notificaciones
            // },
            {
                path: "/autorizaciones",
                sidebarName: "autorizaciones",
                navbarName: "autorizaciones",
                icon: ShieldKeyOutline,
                restricted: !hasPermissionTo(PERSMISOS.autorizaciones),
                component: Autorizaciones
            },
        ] : []
        ),
    ]
    return obj
}
export default getRoutes;
