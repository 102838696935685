import * as React from 'react';
import {useEffect} from 'react';
import makeStyles from "@mui/styles/makeStyles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@mui/material";
import Strings from "../../../assets/strings";
import {ICompraDetalle} from "../../../redux/types/ICompra";
import TopBarDialog from "../../../components/topBar/TopBarDialog";
import {IOferta, IOfertaDetalle} from "../../../redux/types/IOferta";
import {PercentOutline} from "mdi-material-ui";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useParams} from "react-router-dom";
import {TextValidatorMultiple, ValidatorFormCustom} from "../../../helpers/form-validator";

const useStyles = makeStyles((theme:Theme) => ({
    accordion: {
        margin: "0 !important",
        boxShadow: "none",
        borderBottom: "1px solid" +theme.palette.grey[200],
    },
    accordionDetails:{
        padding: 0,
        paddingBottom: 8,
    },
    accordionSummaryRoot: {
        minHeight:"0 !important",
        padding: 0,
    },

    accordionSummaryContent: {
        margin: "0 !important",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        padding:theme.spacing(1)
    },
    inputCont:{
        "& >div":{padding: "7px 0"},
        "& label":{display: "none"}
    }
}));


type IProps = {
    list?:IOferta[],
    onChage:(detalle:ICompraDetalle[])=>void,
    selected:ICompraDetalle[]
}

export default function ListOferta({list,onChage, selected: selectedProp}:IProps) {
    const classes = useStyles();
    let { id:urlComId} = useParams()
    const [openModalofertas, setOpenModalOfertas] =  React.useState(false);
    const [selected, setSelected] =  React.useState<ICompraDetalle[]>([]);

    useEffect(()=>{
        setSelected(selectedProp?.filter(l=>!!l.ofertaId))
    },[selectedProp, setSelected])


    const handleChange = (cantidad:number, oferta:IOfertaDetalle)=>{

        if(isNaN(cantidad) || cantidad <= 0){
            let list =  selected?.filter(s=>(s.articuloId !== oferta.articuloId && s.ofertaId !== oferta.ofertaId));
            setSelected(list)
        }else{
            let found = selected?.find(s=>(s.articuloId === oferta.articuloId && s.ofertaId === oferta.ofertaId));
            if(found){
                let list = selected?.map(s=>{
                    if(s.articuloId === oferta.articuloId && s.ofertaId === oferta.ofertaId)
                        return {...s,comCantidad:cantidad};

                    return s
                })
                setSelected(list)
            }else{
                let item:ICompraDetalle = {
                    accion: 1,
                    ofertaId:oferta.ofertaId,
                    articuloId: oferta.articuloId,
                    articulo: oferta.articulo,
                    comCantidad: cantidad,
                }
                if(urlComId) item.compraId = parseInt(urlComId);

                setSelected(prev => [...prev,item])
            }
        }
    }
    const onAdd = () => {
        onChage([...selectedProp?.filter(l=>!l.ofertaId),...selected]);
        setOpenModalOfertas(false)
    }
    return (
        <>
            <Fab variant="extended" onClick={()=>setOpenModalOfertas(true)} size="small" color="secondary" aria-label="oferta" sx={{position: "absolute", bottom:16, ml:1}} >
                <PercentOutline sx={{ mr: 1 }} />
                Ofertas disponible
            </Fab>
            <Dialog maxWidth="md" onClose={()=>setOpenModalOfertas(false)} open={!!openModalofertas}>
                <TopBarDialog title={Strings.ofertas} onClose={()=>setOpenModalOfertas(false)} nofullScreen/>
                <DialogContent sx={{p:"5px"}}>
                    {!!list?.length && list?.map(oferta=>
                            <Accordion key={oferta.id} defaultExpanded className={classes.accordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    classes={{
                                        root:classes.accordionSummaryRoot,
                                        content:classes.accordionSummaryContent}}
                                >
                                    <Typography color={"primary"} className={classes.heading}>{oferta.ofeDescripcion}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <TableContainer>
                                        <ValidatorFormCustom onSubmit={onAdd} id={"formOferta"}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{Strings.articulo}</TableCell>
                                                        <TableCell>Al comprar</TableCell>
                                                        <TableCell>Recibes</TableCell>
                                                        <TableCell>Catindad oferta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {oferta.detalle?.map((ofe) =>
                                                        <TableRow key={ofe.id} >
                                                            <TableCell>{ofe.articulo}</TableCell>
                                                            <TableCell>{ofe.ofeCantidad}</TableCell>
                                                            <TableCell>{(ofe.ofeCantidadOferta ?? 0) + (ofe.ofeCantidad ?? 0)}</TableCell>
                                                            <TableCell className={classes.inputCont}>
                                                                <TextValidatorMultiple label={ofe.articulo} multiple={ofe.ofeCantidad} name={ofe.articuloId!.toString()} type={"number"} inputProps={{step:ofe.ofeCantidad}} value={selected?.find(s=>s.articuloId===ofe.articuloId && s.ofertaId === ofe.ofertaId)?.comCantidad ?? ""} onChange={({target}:any)=>handleChange(parseInt(target.value), ofe)}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                            <br/>
                                        </ValidatorFormCustom>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenModalOfertas(false)}  color="primary">{Strings.cancelar}</Button>
                    <Button color="primary" type={"submit"} form={"formOferta"}>{Strings.guardar}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
