export const ESTADO_AUTORIZACION = {
    pendiente:1,
    aprobada:2,
    rechazada: 3,
}
export const TIPO_DOCUMENTO = {
    cedula: 1,
    pasaporte: 2,
    licenciaConducir: 3,
    extranjero: 4,
    menor: 5
}
export const TIPO_AUTORIZACION = {
    pacienteIndicacion:1,
    creacionSucursal:2,
    compra:3,
    canje:4
}

export const TIPO_USUARIO = {
    admin:1,
    agente:2,
    paciente:3,
    pariente:4,
    farmacia:5
}
export const SEXO = {
    masculino: "M",
    femenino: "F"
}
export const PERSMISOS = {
   // Del 1 - 9 Seria para permisos Generales.-
    dashboard: 1,
    autorizaciones: 2,
    seguridad: 3,

    pacientes:10,
    pacientesCrear:11,
    pacientesActualizar:12,
    pacientesEliminar:13,
    pacientesGenerarUsuario:14,
//    pacientesSoloVerRelacionadoss: 15,//Este permiso aplica para los Paciente y Parientes, que solo puedan ver los de ellos.

    parientes : 20,
    parientesCrear:21,
    parientesActualizar:22,
    parientesEliminar:23,
    parientesGenerarUsuario:24,

    articulos : 30,
    articulosCrear:31,
    articulosActualizar:32,
    articulosEliminar:33,

    lineas : 40,
    lineasModifcar:41,

    marcas : 45,
    marcasModificar:46,

    farmacias : 50,
    farmaciasCrear:51,
    farmaciasActualizar:52,
    farmaciasEliminar:53,
    farmaciasGenerarUsuario:54,

    medicos : 60,
    medicosCrear:61,
    medicosActualizar:62,
    medicosEliminar:63,

    centrosMedicos : 70,
    centrosMedicosCrear: 71,
    centrosMedicosActualizar: 72,
    centrosMedicosEliminar: 73,

    tarjetas : 80,
    tarjetasCrear: 81,
    tarjetasActualizar: 82,
    tarjetasEliminar: 83,
    tarjetasCrearLote: 84,

    usosMultiples : 90,
    usosMultiplesCrear: 91,
    usosMultiplesActualizar: 92,
    usosMultiplesEliminar: 93,

    canjes : 100,
    canjesCrear: 101,
    canjesActualizar: 102,
    canjesEliminar: 103,

    compras : 110,
    comprasCrear: 111,
    comprasActualizar: 112,
    comprasEliminar: 113,

    planes:120,
    planesCrear:121,
    planesActualizar:122,
    planesEliminar:123,

    ofertas : 130,
    ofertasCrear: 132,
    ofertasActualizar: 133,
    ofertasEliminar: 134,
    ofertasSucursales: 135,
};
export const TIPOUSUARIO = {
    admin:1,
    agente:2,
    paciente:3,
    pariente:4, //Relacionado
    farmacia:5
}
export const TIPOPAGO={
    CUOTA_COMPLETA: 1,
    PAGO_PARCIAL: 2,
    ABONO_CAPITAL: 3,
    AUSTE_CAPITAL: 4,
    SOLO_INTERES: 5,
    SAlDAR:6
};
export const getValueString = (constant = {},value) =>{
    let prop = "";
    Object.keys(constant).forEach((pro,i)=>{
        if(constant[pro] === parseInt(value)){
            prop = pro;
        }
    })
    return prop;
}
export const objToKeyValue = (constant = {}) =>{
    let arr = [];
    Object.keys(constant).forEach((pro,i)=>{
        arr.push({
            key:constant[pro],
            value:pro
        })
    })
    return arr;
}
